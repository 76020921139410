import { NodeOnDiskFile } from '@remix-run/node';
import z from 'zod';
import { db } from './db.server';
import { createForeignKeyValidatedSchema } from './generalValidation';
import bcrypt from 'bcryptjs';

export function createMemberSchema(memberId?: string) {
    return z.object({
        name: z.string().describe('Name'),
        password: z.string().optional().describe('Neues Passwort'),
        aggregationAccount: createForeignKeyValidatedSchema(z.string(), db?.account, iban => ({ iban, ownerId: memberId }), 'Konto existiert nicht').optional().describe('Alle Überweisungen an'),
        avatar: z.instanceof(NodeOnDiskFile ?? File).optional().describe('Avatar'),
        deleteAvatar: z.boolean().default(false).describe('Aktuellen Avatar löschen'),
    });
}

export const LoginSchema = z.object({
    username: z.string().nonempty().describe('Name'),
    password: z.string().nonempty().describe('Passwort'),
}).transform(async ({ username, password }, ctx) => {
    if (!db?.member) return z.NEVER;

    const member = await db.member.findUnique({
        where: { name: username },
    });

    if (!member) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Benutzername oder Passwort falsch.',
        });
        return z.NEVER;
    }

    const isCorrectPassword = await bcrypt.compare(
        password,
        member.passwordHash
    );

    if (!isCorrectPassword) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Benutzername oder Passwort falsch.',
        });
        return z.NEVER;
    }

    return member.id;
});
